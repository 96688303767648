import Request from '@/utils/request'

export function getCompanyListApi (data) {
  return Request({
    url: '/company',
    params: data
  })
}

export function saveCompanyApi (data) {
  return Request({
    url: '/company/save',
    method: 'post',
    data
  })
}
